form {
    padding: 2rem 1rem;
    background: #0E0E0F;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
}

.formTitle {
    font-weight: 500;
    text-align: left;
}

.hide {
    font-size: 0;
}

input, textarea {
    border: none;
    border-radius: 5px;
    background: #292B2C;
    padding: 0.7rem 1rem;
    font-size: 16px;
    font-family: montserrat;
    font-weight: 400;
    color: var(--secondary);
}

textarea {
    min-height: 200px;
}

.btn {
    padding: 8px 16px;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    background: none;
    font-size: 18px;
    text-decoration: none;
    border-radius: 10px;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

@media screen and (min-width: 768px) {
    form {
        padding: 2.5rem;
    }
}