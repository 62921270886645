.iconCon {
    width: 64px;
    height: 64px;
    background: #292B2C;
    border: none;
    padding: 1.25rem;
    border-radius: 10px;
    aspect-ratio: 1 / 1;
    transition: all 0.2s ease-in-out;
}

.iconCon:hover {
    background: #36393B;
}

.iconCon:focus {
    background: #36393B;
}

.iconCon ~ p {
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.iconCon:hover ~ p {
    opacity: 1;
}

.iconCon:focus ~ p {
    opacity: 1;
}

@media screen and (min-width: 600px) {
    .iconCon {
        width: 80px;
        height: 80px;
        padding: 1.5rem;
    }
}

@media screen and (min-width: 1024px) {
    .iconCon ~ p {
        font-size: 16px;
    }
}