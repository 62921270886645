/* Hero Styles */
.hero {
    margin-top: 14rem;  
    overflow-y: hidden;  
}

.heroTxt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.name {
    color: var(--primary);
    font-family: milgran;
    text-transform: uppercase;
    text-align: center;
}

.jobTitle {
    color: var(--secondary);
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
}

.greenStar {
    width: 150px;
    height: 150px;
    left: 0;
    bottom: 0;
    postion: absolute;
    margin-left: -6rem;
    margin-top: 2rem;
}

.orangeStar {
    width: 250px;
    height: 250px;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -5rem;
    margin-right: -7rem;
}

@media screen and (min-width: 768px) {
    .heroTxt {
        margin-left: 3rem;
    }
    
    .name {
        text-align: left;
    }

    .jobTitle {
        text-align: left;
    }

    .heroTxt {
        align-items: flex-start;
    }

    .greenStar {
        width: 200px;
        height: 200px;
        margin-left: -7.5rem;
    }

    .orangeStar {
        width: 350px;
        height: 350px;
        margin-top: -6rem;
        margin-right: -6rem;
    }
}

@media screen and (min-width: 1024px) {
    .greenStar {
        width: 250px;
        height: 250px;
        margin-left: -9rem;
    }

    .orangeStar {
        width: 500px;
        height: 500px;
        margin-top: -10rem;
        margin-right: -8rem;
    }

    .name {
        font-size: 40px;
    }
}

@media screen and (min-width: 1200px) {
    .greenStar {
        width: 300px;
        height: 300px;
        margin-left: -11rem;
    }

    .orangeStar {
        width: 600px;
        height: 600px;
        margin-top: -12rem;
        margin-right: -12rem;
    }

    .name {
        font-size: 56px;
    }

    .jobTitle {
        font-size: 24px;
    }

    .heroTxt {
        margin-left: 5rem;
    }

    .hero {
        margin-top: 16rem;
    }
}

@media screen and (min-width: 1500px) {
    .heroTxt {
        margin-left: 10rem;
    }
}

@media screen and (min-width: 1920px) {
    .heroTxt {
        margin-left: 15rem;
    }
}
/* Hero Styles End */

/* Skills and Education Styles */
.skillsCard {
    background: #0E0E0F;
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardTitle {
    color: #CECECE;
    text-align: center;
    font-weight: 400;
}

.line {
    width: 100%;
    height: 1px;
    background: #36393B;
    margin-top: 0.5rem;
}

.techGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;
}

.techIcon {
    width: 100%;
    height: 100%;
}

.eduCon {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 250px;
}

@media screen and (min-width: 560px) {
    .eduCon {
        max-width: 100%;
    }

    .skillsCard {
        padding: 1rem 1.5rem;
    }

    .techGrid {
        grid-gap: 16px;
    }
}

@media screen and (min-width: 768px) {
    .techGrid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 900px) {
    .eduCon {
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        gap: 0.5rem;
    }
}

@media screen and (min-width: 1024px) {
    .techGrid {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 1200px) {
    .techGrid {
        margin-top: 1rem;
    }
}

.caseStudy {
    border-radius: 10px;
    height: 400px !important;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.testimonialCon {
    max-width: 1000px;
    background-color: var(--secondary);
    border-radius: 10px;
    padding: 3rem;
    color: var(--dark);
    margin: 2rem auto;
    text-align: center;
    transform: translateY(100px);
    opacity: 0;
    transition: all 1.5s ease-in-out;
}

.testimonialCon.active {
    transform: translateY(0);
    opacity: 1;
}
