.subheading {
    font-weight: 400;
}

.backgroundBlue {
    background-color: #02033C;
}

.backgroundWhite {
    background-color: #F3F5F7;
}

.rwiLogoCon {
    width: 100%;
    aspect-ratio: 3 / 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoPng {
    width: 50%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.slideImg { 
    width: 100%;
    height: auto;
}

.caption {
    font-size: 14px;
    color: var(--light);
    text-align: center;
    font-style: italic;
}

.card {
    background: #0E0E0F;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardTitle {
    color: #CECECE;
    text-align: center;
    font-weight: 400;
}

.line {
    width: 100%;
    height: 1px;
    background: #36393B;
    margin-top: 0.5rem;
}

.frenchGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-bottom: 5rem;
}

.mobileGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
}


@media screen and (min-width: 1200px) {
    .logoPng {
        width: 30%;
    }
}
