.caseStudy {
    width: 40%;
}

.caseStudyImg {
    border-radius: 10px;
    height: 400px !important;
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.tagTxt {
    font-size: 12px;
    color: var(--tertiary);
    text-decoration: underline;
}

.projectImg {
    aspect-ratio: 1 / 1;
    height: 300px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    transition: transform 0.5s ease;
}

.projectImg:hover {
    transform: scale(1.05);
}

.projectImg img {
    height: 100%;
    width: auto;
    border-radius: 10px;
}
