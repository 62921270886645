.caseStudies {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.slider {
    margin: 40px 0;
} 

.swiper {
    width: 100%;
}

.swiper-wrapper {
    width: 100%;
}

.swiper-slide {
    width: 80%;
    height: 80%;
    padding: 0 1.5rem;
    cursor: grab;
}

.swiper-slide img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 992px) {
    .swiper {
        overflow: visible;
    }
    
    .swiper-slide {
        cursor: pointer;
        transition: transform 0.5s ease;
    }

    .swiper-slide:hover {
        transform: scale(1.1);
    }
}

@media screen and (min-width: 1024px) {
    .swiper {
        width: 80%;
        max-width: 1200px;
    }
}