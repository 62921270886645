:root {
  --primary: #f05d5e;
  --secondary: #d6e0ec;
  --tertiary: #FFB870;
  --quatrinary: #0F7173;
  --dark: #36393B;
  --light: #EFF3F7;
  --error: #B44647;
  --spacing-one: calc(1rem * 0.25);
  --spacing-two: calc(1rem * 0.5);
  --spacing-three: 1rem;
  --spacing-four: calc(1rem * 1.5);
  --spacing-five: calc(1rem * 3);
  --spacing-six: calc(1rem * 6);
}

@font-face {
  font-family: "Milgran";
  src: local("Milgran"),
  url("./assets/fonts/Milgran.otf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  position: relative;
  scoll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: var(--tertiary-color);
  background: linear-gradient(#0E0E0F, #292B2C);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

/* Scroll Bar */
body::-webkit-scrollbar {
  width: 16px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #0E0E0F;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #36393B;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 5px solid #0E0E0F;  /* creates padding around scroll thumb */
}

.milgran {
  font-family: "Milgran";
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
  line-height: 36px;
}

h3 {
  font-size: 21px;
}

h4 {
  font-size: 18px;
}

p {
  font-size: 1.1rem;
  margin-top: 0;
  line-height: 1.6;
}

a {
  display: block;
}

.wrapper {
  margin-left: 74px;
  padding-bottom: 1px;
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
}

.sectionSpacing {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

@media screen and (min-width: 768px) {
  .wrapper {
    margin-left: 80px;
  }
}

@media screen and (min-width: 1200px) {
  .wrapper {
    margin-left: 194px;
  }
}

.svg {
  font-size: 24px;
  cursor: pointer;
}

.svgLg {
  font-size: 36px;
  cursor: pointer;
}

.cursorPointer {
  cursor: pointer;
}

/* HEADER STYLES */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: var(--dark);
  height: 100vh;
  width: fit-content;
  padding: 1.5rem 1rem;
  transition: width 0.5s ease-in-out;
}

.logo {
  width: 40px;
}

.navItem {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  transition: all 0.2s;
}

.navItem a {
  text-decoration: none;
}

.navItem:hover {
  border-bottom: solid 4px var(--secondary);
}

.activePage {
  border-bottom: solid 4px var(--primary);
  color: var(--secondary);
}

nav a {
  color: var(--secondary);
  font-size: 18px;
  text-transform: uppercase;
}

.menu {
  background: none;
  border: none;
  font-size: 32px;
  color: var(--secondary);
  cursor: pointer;
}

.navbar {
  display: none;
  transition: all 0.2s ease-in-out;
}

.navbarVisible {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.navbarOpen {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

@media screen and (min-width: 768px) {
  .logo {
    width: 48px;
  }

  nav a {
    font-size: 24px;
  }
}

@media screen and (min-width: 1200px) {
  .logo {
    width: 120px;
  }

  .navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .menu {
    display: none;
  }

  .sidebar {
    padding: 1.5rem;
  }
}

.bgPrimary {
  background-color: var(--primary);
}

.bgSecondary {
  background-color: var(--secondary);
}

.bgTertiary {
  background-color: var(--tertiary);
}
.bgQuatrinary {
  background-color: var(--quatrinary);
}

/* FONT UTILS */
.underline {
  text-decoration: underline;
}
.noDecoration {
  text-decoration: none !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.semibold {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-right {
  text-align: right;
}

.text-primary {
  color: var(--primary);
}

.text-secondary {
  color: var(--secondary);
}

.text-tertiary {
  color: var(--tertiary);
}

.text-quatrinary {
  color: var(--quatrinary);
}

.text-light {
  color: var(--light);
}

.text-dark {
  color: var(--dark);
}

.text-error {
  color: var(--error);
}

/* HEIGHT / WIDTH UTILS */
.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.min-100-vh {
  min-height: 100vh;
}

.min-100-vw {
  min-width: 100vw;
}

.fit-content {
  width: fit-content;
  height: fit-content;
}

.max1000 {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.max800 {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* MARGIN/PADDING UTILS */

/* Margin - all sides */
.m-0 {
  margin: 0;
}

.m-1 {
  margin: var(--spacing-one);
}

.m-2 {
  margin: var(--spacing-two);
}

.m-3 {
  margin: var(--spacing-three);
}

.m-4 {
  margin: var(--spacing-four);
}

.m-5 {
  margin: var(--spacing-five);
}

.m-auto {
  margin: auto;
}

/* Margin-bottom */

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: var(--spacing-one);
}

.mb-2 {
  margin-bottom: var(--spacing-two);
}

.mb-3 {
  margin-bottom: var(--spacing-three);
}

.mb-4 {
  margin-bottom: var(--spacing-four);
}

.mb-5 {
  margin-bottom: var(--spacing-five);
}

.mb-auto {
  margin-bottom: auto;
}

/* Margin-top */

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: var(--spacing-one);
}

.mt-2 {
  margin-top: var(--spacing-two);
}

.mt-3 {
  margin-top: var(--spacing-three);
}

.mt-4 {
  margin-top: var(--spacing-four);
}

.mt-5 {
  margin-top: var(--spacing-five);
}

.mt-auto {
  margin-top: auto;
}

/* Margin-right */

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: var(--spacing-one);
}

.mr-2 {
  margin-right: var(--spacing-two);
}

.mr-3 {
  margin-right: var(--spacing-three);
}

.mr-4 {
  margin-right: var(--spacing-four);
}

.mr-5 {
  margin-right: var(--spacing-five);
}

.mr-auto {
  margin-right: auto;
}

/* Margin-left */

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: var(--spacing-one);
}

.ml-2 {
  margin-left: var(--spacing-two);
}

.ml-3 {
  margin-left: var(--spacing-three);
}

.ml-4 {
  margin-left: var(--spacing-four);
}

.ml-5 {
  margin-left: var(--spacing-five);
}

.ml-auto {
  margin-left: auto;
}

/* Margin - x axis */

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.mx-3 {
  margin-right: var(--spacing-three);
  margin-left: var(--spacing-three);
}

.mx-4 {
  margin-right: var(--spacing-four);
  margin-left: var(--spacing-four);
}

.mx-5 {
  margin-right: var(--spacing-five);
  margin-left: var(--spacing-five);
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

/* Margin - y axis */

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.my-3 {
  margin-top: var(--spacing-three);
  margin-bottom: var(--spacing-three);
}

.my-4 {
  margin-top: var(--spacing-four);
  margin-bottom: var(--spacing-four);
}

.my-5 {
  margin-top: var(--spacing-five);
  margin-bottom: var(--spacing-five);
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

/* Padding - all sides */
.p-0 {
  padding: 0;
}

.p-1 {
  padding: var(--spacing-one);
}

.p-2 {
  padding: var(--spacing-two);
}

.p-3 {
  padding: var(--spacing-three);
}

.p-4 {
  padding: var(--spacing-four);
}

.p-5 {
  padding: var(--spacing-five);
}

.p-auto {
  padding: auto;
}

/* padding-bottom */

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: var(--spacing-one);
}

.pb-2 {
  padding-bottom: var(--spacing-two);
}

.pb-3 {
  padding-bottom: var(--spacing-three);
}

.pb-4 {
  padding-bottom: var(--spacing-four);
}

.pb-5 {
  padding-bottom: var(--spacing-five);
}

.pb-auto {
  padding-bottom: auto;
}

/* padding-top */

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: var(--spacing-one);
}

.pt-2 {
  padding-top: var(--spacing-two);
}

.pt-3 {
  padding-top: var(--spacing-three);
}

.pt-4 {
  padding-top: var(--spacing-four);
}

.pt-5 {
  padding-top: var(--spacing-five);
}

.pt-auto {
  padding-top: auto;
}

/* padding-right */

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: var(--spacing-one);
}

.pr-2 {
  padding-right: var(--spacing-two);
}

.pr-3 {
  padding-right: var(--spacing-three);
}

.pr-4 {
  padding-right: var(--spacing-four);
}

.pr-5 {
  padding-right: var(--spacing-five);
}

.pr-auto {
  padding-right: auto;
}

/* padding-left */

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: var(--spacing-one);
}

.pl-2 {
  padding-left: var(--spacing-two);
}

.pl-3 {
  padding-left: var(--spacing-three);
}

.pl-4 {
  padding-left: var(--spacing-four);
}

.pl-5 {
  padding-left: var(--spacing-five);
}

.pl-auto {
  padding-left: auto;
}

/* padding - x axis */

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.px-3 {
  padding-right: var(--spacing-three);
  padding-left: var(--spacing-three);
}

.px-4 {
  padding-right: var(--spacing-four);
  padding-left: var(--spacing-four);
}

.px-5 {
  padding-right: var(--spacing-five);
  padding-left: var(--spacing-five);
}

.px-auto {
  padding-right: auto;
  padding-left: auto;
}

/* Padding - y axis */

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.py-3 {
  padding-top: var(--spacing-three);
  padding-bottom: var(--spacing-three);
}

.py-4 {
  padding-top: var(--spacing-four);
  padding-bottom: var(--spacing-four);
}

.py-5 {
  padding-top: var(--spacing-five);
  padding-bottom: var(--spacing-five);
}

.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}

/* FLEX CONTENT */
.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-flex-start {
  justify-content: flex-start;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.align-stretch {
  align-items: stretch;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.gapXs {
  gap: 0.5rem;
}

.gapSm {
  gap: 1rem;
}

.gapMd {
  gap: 1.5rem;
}

.gapLg {
  gap: 2.5rem;
}

.gapXl {
  gap: 5rem;
}

.flex-wrap {
  flex-wrap: wrap;
}

.col-auto {
  flex-grow: 1;
  flex-basis: 0;
}

.col-1 {
  flex: 0 0 calc(100% * 1 / 12 - 0.5%);
}

.col-2 {
  flex: 0 0 calc(100% * 2 / 12 - 0.5%);
}

.col-3 {
  flex: 0 0 calc(100% * 3 / 12 - 0.5%);
}

.col-4 {
  flex: 0 0 calc(100% * 4 / 12 - 0.5%);
}

.col-5 {
  flex: 0 0 calc(100% * 5 / 12 - 0.5%);
}

.col-6 {
  flex: 0 0 calc(100% * 6 / 12 - 0.5%);
}

.col-7 {
  flex: 0 0 calc(100% * 7 / 12 - 0.5%);
}

.col-8 {
  flex: 0 0 calc(100% * 8 / 12 - 0.5%);
}

.col-9 {
  flex: 0 0 calc(100% * 9 / 12 - 0.5%);
}

.col-10 {
  flex: 0 0 calc(100% * 10 / 12 - 0.5%);
}

.col-11 {
  flex: 0 0 calc(100% * 11 / 12 - 0.5%);
}

.col-12 {
  flex: 0 0 100%;
}

/* col-sm */
@media screen and (min-width: 640px) {
  .col-sm-auto {
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-sm-1 {
    flex: 0 0 calc(100% * 1 / 12 - 0.5%);
  }

  .col-sm-2 {
    flex: 0 0 calc(100% * 2 / 12 - 0.5%);
  }

  .col-sm-3 {
    flex: 0 0 calc(100% * 3 / 12 - 0.5%);
  }

  .col-sm-4 {
    flex: 0 0 calc(100% * 4 / 12 - 0.5%);
  }

  .col-sm-5 {
    flex: 0 0 calc(100% * 5 / 12 - 0.5%);
  }

  .col-sm-6 {
    flex: 0 0 calc(100% * 6 / 12 - 0.5%);
  }

  .col-sm-7 {
    flex: 0 0 calc(100% * 7 / 12 - 0.5%);
  }

  .col-sm-8 {
    flex: 0 0 calc(100% * 8 / 12 - 0.5%);
  }

  .col-sm-9 {
    flex: 0 0 calc(100% * 9 / 12 - 0.5%);
  }

  .col-sm-10 {
    flex: 0 0 calc(100% * 10 / 12 - 0.5%);
  }

  .col-sm-11 {
    flex: 0 0 calc(100% * 11 / 12 - 0.5%);
  }

  .col-sm-12 {
    flex: 0 0 100%;
  }

  .justify-space-between-sm {
    justify-content: space-between;
  }

  .justify-space-around-sm {
    justify-content: space-around;
  }

  .justify-flex-start-sm {
    justify-content: flex-start;
  }

  .justify-flex-end-sm {
    justify-content: flex-end;
  }

  .justify-center-sm {
    justify-content: center;
  }

  .align-stretch-sm {
    align-items: stretch;
  }

  .align-end-sm {
    align-items: flex-end;
  }

  .align-center-sm {
    align-items: center;
  }
}

/* col-md */
@media screen and (min-width: 768px) {
  .col-md-auto {
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-md-1 {
    flex: 0 0 calc(100% * 1 / 12 - 0.5%);
  }

  .col-md-2 {
    flex: 0 0 calc(100% * 2 / 12 - 0.5%);
  }

  .col-md-3 {
    flex: 0 0 calc(100% * 3 / 12 - 0.5%);
  }

  .col-md-4 {
    flex: 0 0 calc(100% * 4 / 12 - 0.5%);
  }

  .col-md-5 {
    flex: 0 0 calc(100% * 5 / 12 - 0.5%);
  }

  .col-md-6 {
    flex: 0 0 calc(100% * 6 / 12 - 0.5%);
  }

  .col-md-7 {
    flex: 0 0 calc(100% * 7 / 12 - 0.5%);
  }

  .col-md-8 {
    flex: 0 0 calc(100% * 8 / 12 - 0.5%);
  }

  .col-md-9 {
    flex: 0 0 calc(100% * 9 / 12 - 0.5%);
  }

  .col-md-10 {
    flex: 0 0 calc(100% * 10 / 12 - 0.5%);
  }

  .col-md-11 {
    flex: 0 0 calc(100% * 11 / 12 - 0.5%);
  }

  .col-md-12 {
    flex: 0 0 100%;
  }

  .justify-space-between-md {
    justify-content: space-between;
  }

  .justify-space-around-md {
    justify-content: space-around;
  }

  .justify-flex-start-md {
    justify-content: flex-start;
  }

  .justify-flex-end-md {
    justify-content: flex-end;
  }

  .justify-center-md {
    justify-content: center;
  }

  .align-stretch-md {
    align-items: stretch;
  }

  .align-end-md {
    align-items: flex-end;
  }

  .align-start-md {
    align-items: flex-start;
  }

  .align-center-md {
    align-items: center;
  }
}

/* col-lg */
@media screen and (min-width: 992px) {
  .col-md-auto {
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-lg-1 {
    flex: 0 0 calc(100% * 1 / 12 - 0.5%);
  }

  .col-lg-2 {
    flex: 0 0 calc(100% * 2 / 12 - 0.5%);
  }

  .col-lg-3 {
    flex: 0 0 calc(100% * 3 / 12 - 0.5%);
  }

  .col-lg-4 {
    flex: 0 0 calc(100% * 4 / 12 - 0.5%);
  }

  .col-lg-5 {
    flex: 0 0 calc(100% * 5 / 12 - 0.5%);
  }

  .col-lg-6 {
    flex: 0 0 calc(100% * 6 / 12 - 0.5%);
  }

  .col-lg-7 {
    flex: 0 0 calc(100% * 7 / 12 - 0.5%);
  }

  .col-lg-8 {
    flex: 0 0 calc(100% * 8 / 12 - 0.5%);
  }

  .col-lg-9 {
    flex: 0 0 calc(100% * 9 / 12 - 0.5%);
  }

  .col-lg-10 {
    flex: 0 0 calc(100% * 10 / 12 - 0.5%);
  }

  .col-lg-11 {
    flex: 0 0 calc(100% * 11 / 12 - 0.5%);
  }

  .col-lg-12 {
    flex: 0 0 100%;
  }

  .justify-space-between-lg {
    justify-content: space-between;
  }

  .justify-space-around-lg {
    justify-content: space-around;
  }

  .justify-flex-start-lg {
    justify-content: flex-start;
  }

  .justify-flex-end-lg {
    justify-content: flex-end;
  }

  .justify-center-lg {
    justify-content: center;
  }

  .align-stretch-lg {
    align-items: stretch;
  }

  .align-start-lg {
    align-items: flex-start;
  }

  .align-center-lg {
    align-items: center;
  }
}

/* col-xl */
@media screen and (min-width: 1200px) {
  .col-xl-1 {
    flex: 0 0 calc(100% * 1 / 12 - 0.5%);
  }

  .col-xl-2 {
    flex: 0 0 calc(100% * 2 / 12 - 0.5%);
  }

  .col-xl-3 {
    flex: 0 0 calc(100% * 3 / 12 - 0.5%);
  }

  .col-xl-4 {
    flex: 0 0 calc(100% * 4 / 12 - 0.5%);
  }

  .col-xl-5 {
    flex: 0 0 calc(100% * 5 / 12 - 0.5%);
  }

  .col-xl-6 {
    flex: 0 0 calc(100% * 6 / 12 - 0.5%);
  }

  .col-xl-7 {
    flex: 0 0 calc(100% * 7 / 12 - 0.5%);
  }

  .col-xl-8 {
    flex: 0 0 calc(100% * 8 / 12 - 0.5%);
  }

  .col-xl-9 {
    flex: 0 0 calc(100% * 9 / 12 - 0.5%);
  }

  .col-xl-10 {
    flex: 0 0 calc(100% * 10 / 12 - 0.5%);
  }

  .col-xl-11 {
    flex: 0 0 calc(100% * 11 / 12 - 0.5%);
  }

  .col-xl-12 {
    flex: 0 0 100%;
  }

  .justify-space-between-xl {
    justify-content: space-between;
  }

  .justify-space-around-xl {
    justify-content: space-around;
  }

  .justify-flex-start-xl {
    justify-content: flex-start;
  }

  .justify-flex-end-xl {
    justify-content: flex-end;
  }

  .justify-center-xl {
    justify-content: center;
  }

  .align-stretch-xl {
    align-items: stretch;
  }

  .align-end-xl {
    align-items: flex-end;
  }

  .align-center-xl {
    align-items: center;
  }
}